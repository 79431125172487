<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol md="2">
            <MDBInput :label="$t('COMMON.EMAIL')" :placeholder="$t('COMMON.EMAIL')" v-model="where.email" />
          </MDBCol>
          <MDBCol md="2">
            <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
          </MDBCol>
          <MDBCol md="2">
            <select class="form-select" v-model="where.status">
              <option value="">管理員狀態</option>
              <option value="1">{{$t("MEMBER.STATUS_1")}}</option>
              <option value="0">{{$t("MEMBER.STATUS_0")}}</option>
            </select>
          </MDBCol>
          <MDBCol class="mb-2 text-end">
            <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("MANAGERS.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:login_at="{ item }">
            {{ (item.authentications.length > 0)?Common.TimeFormat(item.authentications[0].login_at):"" }}
          </template>
          <template v-slot:login_count="{ item }">
            {{ item.authentications.length }}
          </template>
          <template v-slot:status="{ item }">
            <div class="badge  text-wrap" :class="{'bg-danger':(item.status == 0),'bg-success':(item.status == 1) }">
            {{ $t("MEMBER.STATUS_" + item.status) }}
            </div>
          </template>
          <template v-slot:enable="{ item }">
            <div class="form-check form-switch cursor-pointer" @click="StatusAction(item.uuid,+!item.status)">
              <input class="form-check-input" type="checkbox" :id="item.uuid" :checked="item.status == 1" disabled>
            </div>
          </template>
          <!-- 操作template -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
	<ManagerForm action="insert" v-model:model="add_dialog"></ManagerForm>
	<ManagerForm action="update" v-model:model="edit_dialog" v-model:uuid="EditUUid"></ManagerForm>
</template>

<style scope>
  .cursor-pointer {
    cursor: pointer;
  }
  .form-check-input:disabled {
    opacity: 1;
  }
</style>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ManagerForm from '@/components/Form/Manager/ManagerForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
export default {
  name: "Managers",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		ManagerForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const EditUUid = ref("");
    const total = ref(0);
    const PermissionItems = reactive([]);
    const RoleItems = reactive([]);
    const desserts = ref([]);
    const Common = CommonService;
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      email: "",
      name: "",
      status: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",width:"50px",class: "text-center"
      },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.EMAIL"), value: "email" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.LAST_LOGIN_AT"), value: "login_at" },
      { text: i18n.t("COMMON.LOGINCOUNT"), value: "login_count" ,width:"150px",class: "text-center"},
      { text: i18n.t("COMMON.STATUS"), value: "status" ,width:"100px",class: "text-center"},
      { text: i18n.t("COMMON.ENABLE"), value: "enable" ,width:"50px",class: "text-center"},
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,width:"50px",class: "text-center"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,width:"50px",class: "text-center"}
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Manager",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetPermission = function() {
      return new Promise(resolve => {
        ApiService.get("/Admin/Permission").then(response => {
          if (response.status == 200) {
            PermissionItems.push(...response.data.data);
            resolve(response);
          }
        });
      });
    };

    const GetRole = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Role",
          {
            params: CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["created_at"],
              sortDesc: []
            })
          }
        ).then(response => {
          if (response.status == 200) {
            RoleItems.push(...response.data.data.data);
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Manager/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    const StatusAction = function(uuid,status) {
      CommonService.StatusSwal(!status).then(result => {
        if (result.isConfirmed) {
          ApiService.update("/Admin/v1/ManagerStatus", uuid,{ status: status}).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
      PageLoading,
      add_dialog,
      edit_dialog,
      loading,
      options,
      headers,
      total,
      EditUUid,
      desserts,
      Common,
      PermissionItems,
      RoleItems,
      GetTable,
      GetPermission,
      GetRole,
      DeleteAction,
      StatusAction,
      where
    };
  },
  mounted() {
    Promise.all([
      this.GetTable(),
      this.GetPermission(),
      this.GetRole()
    ])
    .then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
  provide () {
    return {
      GetTable: this.GetTable,
      permissions: this.PermissionItems,
      roles: this.RoleItems
    }
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  }
};
</script>